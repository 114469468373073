/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Layout from "../../components/layout";
import Container from "../../components/container";
import {mainContent, introSection, buttonGroup, description, marginTopSubstitute, smallMarginTopSubstitute} from "../subpage.module.scss";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(Layout, {
    pageTitle: "News",
    neutralPage: true
  }, React.createElement("main", {
    className: mainContent
  }, React.createElement("section", {
    className: introSection
  }, React.createElement(Container, {
    mdxPageWidth: true
  }, React.createElement("div", {
    className: description
  }, "Artikel - 23. Oktober 2024"), React.createElement(_components.h1, null, "ti-e Portalwelt: Digitale Hilfe im Spital entlang des Behandlungspfads"), React.createElement("div", {
    className: "text-medium"
  }, React.createElement(_components.p, null, "Die ti-e Portalwelt revolutioniert die Gesundheitsversorgung durch eine modulare Plattform, die administrative Prozesse strafft und die Zusammenarbeit\nzwischen medizinischem Personal und Patienten verbessert. Höchste Sicherheitsstandards und flexible Anpassungsmöglichkeiten garantieren eine zukunftssichere Lösung.")), React.createElement(_components.p, null, "Zwei IT-Routiniers aus Spitälern zeigen sich äusserst zufrieden. Erfahren Sie mehr über unsere Ansätze und die Vorteile, die sie für das Gesundheitswesen mit sich bringen,\nim neusten Artikel und Interview im clinicum Magazin."), React.createElement("div", {
    style: {
      marginBottom: 20
    },
    className: buttonGroup
  }, React.createElement("a", {
    href: "https://www.clinicum.ch/images/getFile?t=ausgabe_artikel&f=dokument&id=3172",
    className: "button-outline blue",
    target: "_blank",
    rel: "noreferrer"
  }, " Zum Artikel & Interview"), " "), React.createElement("div", {
    style: {
      marginBottom: 40
    },
    className: buttonGroup
  }, React.createElement("a", {
    href: "https://www.tie.ch/portale/",
    className: "button-outline blue",
    target: "_blank",
    rel: "noreferrer"
  }, " Mehr Infos zu unseren Portalen "), " "), React.createElement(_components.p, null, "Bleiben Sie über neueste Informationen und Veranstaltungen von the i-engineers stets informiert und melden Sie sich für unseren Newsletter an."), React.createElement("div", {
    className: buttonGroup
  }, React.createElement("a", {
    href: "https://www.tie.ch/landingpages/newsletter-anmeldung",
    className: "button-outline blue",
    target: "_blank",
    rel: "noreferrer"
  }, " Zur Newsletter Anmeldung "), " ")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
